import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/pro-light-svg-icons"
import { faPhone } from "@fortawesome/pro-light-svg-icons"
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons"
import { faClock } from "@fortawesome/pro-light-svg-icons"
import { faFax } from "@fortawesome/pro-light-svg-icons"
import SubpageTopimage from "../components/SubpageTopimage"
import ContactForm from "../components/ContactForm"
import MapPreviewImage from "../components/images/MapPreviewImage"

// import UserForm from "../components/UserForm"

const Title = styled.h1``

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    /* border-bottom: 3px solid ${colors.black}; */
    margin-bottom: 3rem;
  }
`

const Textwrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;
  h1 {
    padding: 1rem 1rem 0;
  }
  ${mq.tablet} {
    padding: 1rem 1rem 0;
    width: 35%;
    margin-right: 1rem;
    h1 {
      padding: 0;
      text-align: left;
      border-bottom: none;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 0;
  }
`

const GoogleMapsWrapper = styled.div`
  flex: 0 1 auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: 1rem;
  margin-top: 2rem;
  ${mq.tablet} {
    margin-top: 6.2rem;
    width: 59%;
  }
  picture {
    opacity: 0.4;
  }
  p {
    font-size: 1rem;
    color: #eee;
    font-weight: bold;
    a {
      font-size: 1.1rem;
      color: white;
      font-weight: bold;
    }
  }
  .ladenButton {
    color: white;
    background: ${colors.green};
    display: inline-block;
    padding: 0.5rem 1rem 0.7rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border: none;
    outline: none;
    font-weight: bold;
    &:hover,
    &:focus {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  .maptext {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    padding: 120px 1rem;
    background: rgba(0, 0, 0, 0.4);
    line-height: 1.5;
  }
`

const Contactdata = styled.div`
  margin-bottom: 0;
  a {
    text-decoration: none;
    &:hover,
    &:focus {
      div {
        background: #ececec;
      }
    }
  }
  .firstlink {
    border-top: none;
  }
`

const Contactfield = styled.div`
  background: ${colors.lightgrey};
  padding: 1rem;
  margin: 1rem 1rem 0 0;
  width: 100%;
  display: flex;
  flex-flow: row;
  .faicon {
    margin-right: 1rem;
    flex: 0 1 auto;
    align-self: center;
  }
  p {
    margin-bottom: 0;
  }

  ${mq.tablet} {
    .faicon {
      margin-right: 2rem;
    }
  }
`

// const imaginaryUser = {
//   email: "",
//   username: "",
//   imaginaryThingId: null,
// }

const ContactPageTemplate = ({ data }) => {
  function handleClick(e) {
    e.preventDefault()

    // Create Element.remove() function if not exist
    if (!("remove" in Element.prototype)) {
      Element.prototype.remove = function() {
        if (this.parentNode) {
          this.parentNode.removeChild(this)
        }
      }
    }

    var maptext = document.getElementById("maptext")
    maptext.remove()
    var div = document.getElementById("mapsframe")
    div.firstElementChild.remove()
    var gmapiframe =
      "<iframe id='kontakt' title='Google Maps Hauenstein Estriche' width='100%' height='433px' src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2569.8047432912676!2d11.5045463!3d49.902469!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a1bd6c5cb79629%3A0xa16ea717312d8fa5!2sHauenstein+Estriche+Peter+Hauenstein+e.K.!5e0!3m2!1sde!2sde!4v1559668239912!5m2!1sde!2sde' frameborder='0' allowfullscreen />"
    div.innerHTML += gmapiframe
  }

  return (
    <Layout>
      <SEO
        title={data.wordpressPage.title}
        description={data.wordpressPage.excerpt}
      />
      <SubpageTopimage
        objectFit="cover"
        objectPosition="50% 50%"
        fluid={
          data.wordpressPage.featured_media.localFile.childImageSharp.fluid
        }
        alt={data.wordpressPage.title}
      />
      <Wrapper>
        <Textwrapper>
          <Title>{data.wordpressPage.title}</Title>
          <Contactdata>
            <a
              key="Anschrift"
              href="https://www.google.com/maps/dir/?api=1&destination=Hauenstein+Estriche+Peter+Hauenstein+e.K.,+Am+Mailand+9,+95503+Hummeltal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Contactfield className="firstlink" title="Anschrift">
                <FontAwesomeIcon
                  className="faicon"
                  icon={faMapMarkerAlt}
                  size="lg"
                />{" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.acf.anschrift,
                  }}
                />
              </Contactfield>
            </a>
            <a
              key="Telefonnummer"
              href={"tel:" + data.wordpressPage.acf.telefonnummer}
              title="Telefon"
            >
              <Contactfield>
                <FontAwesomeIcon size="lg" className="faicon" icon={faPhone} />

                {data.wordpressPage.acf.telefonnummer}
              </Contactfield>
            </a>
            <a href={"tel:" + data.wordpressPage.acf.fax} title="Fax" key="Fax">
              <Contactfield>
                <FontAwesomeIcon size="lg" className="faicon" icon={faFax} />

                {data.wordpressPage.acf.fax}
              </Contactfield>
            </a>
            <a
              href={"mailto:" + data.wordpressPage.acf.e_mail}
              title="E-Mail"
              key="EMail"
            >
              <Contactfield>
                <FontAwesomeIcon
                  size="lg"
                  className="faicon"
                  icon={faEnvelope}
                />{" "}
                {data.wordpressPage.acf.e_mail}
              </Contactfield>
            </a>
            <Contactfield key="oeffnungszeiten">
              <FontAwesomeIcon size="lg" className="faicon" icon={faClock} />
              <div
                dangerouslySetInnerHTML={{
                  __html: data.wordpressPage.acf.offnungszeiten,
                }}
              />
            </Contactfield>
          </Contactdata>

          <div
            dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
          />
        </Textwrapper>
        <GoogleMapsWrapper id="mapsframe">
          <MapPreviewImage />
          <div id="maptext" className="maptext">
            <p>
              Mit dem Laden der Karte akzeptieren
              <br /> Sie die Datenschutzerklärung von Google.
              <br />
              <a
                href="https://policies.google.com/privacy"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Mehr erfahren
              </a>
            </p>
            <button className="ladenButton" onClick={handleClick}>
              Karte laden
            </button>
          </div>
        </GoogleMapsWrapper>
      </Wrapper>

      <Wrapper>
        <Textwrapper style={{ padding: ".8rem", marginTop: "2rem" }}>
          <h2 id="angebot">Angebot einholen</h2>
          <p>
            Gerne kümmern wir uns um ein Angebot für Sie. <br />
            In folgendem Formular können Sie uns Details zu Ihrem Bauvorhaben
            zukommen lassen und dadurch den{" "}
            <strong>Prozess beschleunigen</strong>. Alternativ können Sie
            natürlich auch gerne unter oben stehender Nummer anrufen.
          </p>
          {/* <UserForm user={imaginaryUser} /> */}
          <ContactForm />
          {/* <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <label>
              Name <input type="text" name="name" />
            </label>
          </p>
          <p>
            <label>
              Email <input type="email" name="email" />
            </label>
          </p>
          <p>
            <button type="submit">Send</button>
          </p>
        </form> */}
        </Textwrapper>
      </Wrapper>
    </Layout>
  )
}
export default ContactPageTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      acf {
        anschrift
        telefonnummer
        fax
        e_mail
        offnungszeiten
      }
    }
  }
`
