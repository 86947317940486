import React from "react"
// import { navigate } from "gatsby-link"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/pro-light-svg-icons"

const FormWrapper = styled.div`
  .contactform {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.35);
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    min-height: 400px;
    padding: 1rem;
    ${mq.xl} {
      padding: 2rem;
    }
    fieldset {
      margin: 0.3rem;
      width: 100%;
      flex: 1 0 auto;
      border: none;
      .textarea-address {
        width: 100% !important;
      }
      ${mq.xl} {
        border: 1px solid lightgrey;
      }
      legend {
        font-size: 1.1rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      ${mq.tablet} {
        width: 50%;
        padding: 0 1rem;
      }
      ${mq.xl} {
        width: 30%;
        padding: 0 1rem;
      }
      label {
        font-size: 0.9rem;
        font-weight: bold;
        div {
          font-weight: normal;
        }
      }
      p {
        label {
          font-size: 0.9rem;
          font-weight: bold;
          input {
            font-weight: normal;
            width: 100%;
          }
          .textarea {
            font-weight: normal;
            min-height: 430px;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .bottom {
      width: 100%;
      padding: 0 0 0 0.4rem;
      ${mq.tablet} {
        padding: 0 1.2rem;
      }
      ${mq.xl} {
        padding: 0.3rem;
      }
      p {
        .absenden {
          background: ${colors.green};
          border-radius: 30px;
          text-transform: uppercase;
          font-weight: bold;
          color: white;
          padding: 0.5rem 2rem;
          border: 0;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.45);
          &:hover,
          &:focus {
            opacity: 0.7;
            cursor: pointer;
          }
        }
        .checkboxlabel {
          display: flex;
          flex-flow: row wrap;
          width: 100%;
          border: 1px dashed #aaa;
          padding: 0.3rem;
          margin: 0;
          &:hover,
          &:focus {
            cursor: pointer;
            background: #eee;
          }
          input {
            align-self: center;
            width: 30px;
            flex: 0 1 auto;
            ${mq.xl} {
              width: 50px;
            }
          }
          span {
            width: 80%;
            flex: 1 0 auto;
          }
        }
      }
    }
  }
`

// function encode(data) {
//   return Object.keys(data)
//     .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
//     .join("&")
// }

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
    console.log(this.state)
  }

  // handleSubmit = e => {
  //   e.preventDefault()

  //   const form = e.target
  //   console.log(...this.state)
  //   fetch("/", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //     body: encode({
  //       "form-name": form.getAttribute("name"),
  //       ...this.state,
  //     }),
  //   })
  //     .then(() => navigate(form.getAttribute("action")))
  //     .catch(error => alert(error))
  // }

  render() {
    return (
      <FormWrapper>
        <form
          id="angebot"
          className="contactform"
          name="Angebotsformular"
          method="post"
          action="https://mail.hauenstein-estriche.de"
        >
          {/* <form
          id="angebot"
          className="contactform"
          name="Angebotsformular"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        > */}
          {/* Left Side  */}
          <fieldset className="flex">
            <legend>Ihre Daten</legend>
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="Angebotsformular" />
            {/* <input type="hidden" value="Angebotsanfrage" /> */}
            {/* <p hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </p> */}
            <p hidden>
              <label>
                <input name="secondname" />
              </label>
            </p>
            <p>
              <label>
                Name:
                <br />
                <input
                  required
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  placeholder="Max Mustermann"
                />
              </label>
            </p>
            <p>
              <label>
                Firmenname (optional):
                <br />
                <input
                  type="text"
                  name="firmenname"
                  onChange={this.handleChange}
                  placeholder="Mustermann GmbH"
                />
              </label>
            </p>
            <p>
              <label>
                Ihre Adresse
                <br />
                <textarea
                  className="textarea-address"
                  required
                  type="text"
                  name="adresse"
                  autocomplete="street-address"
                  onChange={this.handleChange}
                  placeholder="Musterstraße 16&#10;95444 Bayreuth"
                />
              </label>
            </p>
            <p>
              <label>
                E-Mail:
                <br />
                <input
                  required
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                  placeholder="max@mustermann.de"
                />
              </label>
            </p>
            <p>
              <label>
                Telefonnummer:
                <br />
                <input
                  required
                  type="tel"
                  pattern="^[0-9-+ s()]*$"
                  name="telefon"
                  onChange={this.handleChange}
                  placeholder="0921 90909090"
                />
              </label>
            </p>
          </fieldset>

          {/* Center */}
          <fieldset className="flex">
            <legend>Ihr Projekt</legend>
            <p>
              <label>
                Projektname:
                <br />
                <input
                  required
                  type="text"
                  name="projektname"
                  onChange={this.handleChange}
                  placeholder="Estrich ins Einfamilienhaus"
                />
              </label>
            </p>
            <p>
              <label>
                Wann:
                <br />
                <input
                  required
                  type="text"
                  name="wann"
                  onChange={this.handleChange}
                  placeholder="01.01.2020 - 01.02.2020"
                  maxLength="100"
                />
              </label>
            </p>
            <p>
              <label>
                Projektadresse (falls abweichend)
                <br />
                <textarea
                  className="textarea-address"
                  type="text"
                  name="projektadresse"
                  autocomplete="street-address-business"
                  onChange={this.handleChange}
                  placeholder="Musterstraße 16&#10;95444 Bayreuth"
                />
              </label>
            </p>
            {/* <p>
              <label>
                Straße & Hausnummer
                <br />
                <input
                  required
                  type="text"
                  name="address-line1"
                  autocomplete="address-line1"
                  onChange={this.handleChange}
                  placeholder="Teststraße 16"
                />
              </label>
            </p>
            <p>
              <label>
                Anschrift Zusatz
                <br />
                <input
                  type="text"
                  name="address-line2"
                  autocomplete="address-line2"
                  onChange={this.handleChange}
                  placeholder=""
                />
              </label>
            </p>
            <p>
              <label>
                Postleitzahl
                <br />
                <input
                  type="text"
                  name="postal-code"
                  autocomplete="postal-code"
                  onChange={this.handleChange}
                  placeholder="95444"
                />
              </label>
            </p>
            <p>
              <label>
                Stadt
                <br />
                <input
                  type="text"
                  name="address-level2"
                  autocomplete="address-level2"
                  onChange={this.handleChange}
                  placeholder="Bayreuth"
                />
              </label>
            </p> */}
            <p>
              <label>
                Wie viele qm²? (optional)
                <br />
                <input
                  type="text"
                  name="quadratmeter"
                  onChange={this.handleChange}
                  placeholder="ca. 120m²"
                  maxLength="20"
                />
              </label>
            </p>
            <p>
              <label>
                Welche Leistungen? (optional)
                <br />
                <input
                  type="text"
                  name="leistungen"
                  onChange={this.handleChange}
                  placeholder="z.B. Estrich, Sanierung, ..."
                  maxLength="200"
                />
              </label>
            </p>
          </fieldset>

          {/* right */}
          <fieldset className="flex">
            <legend>Ihre Nachricht (optional)</legend>
            <p>
              <label>
                {/* Nachricht: (optional) */}
                <br />
                <textarea
                  placeholder="Wenn Sie möchten, können Sie uns hier ihr Projekt näher beschreiben."
                  className="textarea"
                  name="nachricht"
                  onChange={this.handleChange}
                  maxLength="1200"
                />
              </label>
            </p>
          </fieldset>

          {/* Bottom */}
          <div className="bottom">
            <p>
              <label className="checkboxlabel">
                <input
                  required
                  className="datenschutz"
                  type="checkbox"
                  name="datenschutz"
                  onChange={this.handleChange}
                />
                <span>
                  Mit der Nutzung dieses Formulars erklären Sie sich mit unserer{" "}
                  <a
                    href="/datenschutz/"
                    target="_blank"
                    rel="noopener nofollow"
                  >
                    Datenschutzerklärung
                  </a>{" "}
                  sowie der Speicherung und Verarbeitung ihrer Daten durch diese
                  Webseite einverstanden.
                </span>
              </label>
            </p>
            <p style={{ marginBottom: "0" }}>
              <button className="absenden" type="submit">
                <FontAwesomeIcon
                  size="lg"
                  className="faicon"
                  icon={faEnvelope}
                  style={{ marginRight: ".4rem" }}
                />{" "}
                Absenden
              </button>
            </p>
          </div>
        </form>
      </FormWrapper>
    )
  }
}
